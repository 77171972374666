import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; 
import Menu from './Menu';
import '../styles/AdminDashborad.css'; 

const ReportesMantenimientoTitanium = () => {
    const navigate = useNavigate();
    const [fechaDesde, setFechaDesde] = useState('');
    const [fechaHasta, setFechaHasta] = useState('');
    const [pisoSeleccionado, setPisoSeleccionado] = useState('TODOS');
    const [tipoSeleccionado, setTipoSeleccionado] = useState('TODOS');
    const [tiposChecklist, setTiposChecklist] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        
        const fetchTiposChecklist = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(process.env.REACT_APP_API_REACT_TIPOS_CHECKLIST_TITANIUM, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setTiposChecklist(response.data.map(item => item.equipos)); // Extraer solo los tipos
            } catch (error) {
                console.error('Error al cargar los tipos de checklist:', error);
            }
        };

        fetchTiposChecklist();
    }, []);

    const handleGenerateReport = async (e) => {
        e.preventDefault(); // Prevenir el comportamiento por defecto del formulario

        // Validar fechas
        if (!fechaDesde || !fechaHasta) {
            alert('Por favor, ingresa ambas fechas.');
            return;
        }

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('fechaDesde', fechaDesde);
                formData.append('fechaHasta', fechaHasta);
        formData.append('piso', pisoSeleccionado);
        formData.append('tipoChecklist', tipoSeleccionado);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_REACT_GENERAR_REPORTE_TITANIUM}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'reporte_mantenimiento.pdf'; // Nombre del archivo
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url); // Liberar el objeto URL
            } else {
                alert('Error al generar el reporte con response.ok, por favor intenta nuevamente.');
            }
        } catch (error) {
            console.error('Error al generar el reporte API:', error);
            alert('Error al generar el reporte, por favor intenta nuevamente.');
        }
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="reportes-container">
            <Menu userName={localStorage.getItem('userName')} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
            <main className="content">
                <div className="dashboard-container">
                    <h2>Generar Reporte de Mantenimiento</h2>
                    <form onSubmit={handleGenerateReport}>
                        <div>
                            <label>
                                Fecha Desde:
                                <input
                                    type="date"
                                    value={fechaDesde}
                                    onChange={(e) => setFechaDesde(e.target.value)}
                                    required
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                Fecha Hasta:
                                <input
                                    type="date"
                                    value={fechaHasta}
                                    onChange={(e) => setFechaHasta(e.target.value)}
                                    required
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                Piso:
                                <select value={pisoSeleccionado} onChange={(e) => setPisoSeleccionado(e.target.value)}>
                                    <option value="TODOS">Todos los Pisos</option>
                                    {Array.from({ length: 25 }, (_, i) => (
                                        <option key={i + 1} value={i + 1}>{`Piso ${i + 1}`}</option>
                                    ))}
                                    {['S1', 'S2', 'S3', 'S4', 'S5'].map((piso) => (
                                        <option key={piso} value={piso}>{`Piso ${piso}`}</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                Tipo de Checklist:
                                <select value={tipoSeleccionado} onChange={(e) => setTipoSeleccionado(e.target.value)}>
                                    <option value="TODOS">Todos los Tipos</option>
                                    {tiposChecklist.map((tipo, index) => (
                                        <option key={index} value={tipo}>{tipo}</option>
                                    ))}
									<option value="CLIMA">CLIMA</option>
                                </select>
                            </label>
                        </div>
                        <button type="submit" className="btn-generate-report">
                            Generar Reporte
                        </button>
                    </form>
                </div>
            </main>
        </div>
    );
};

export default ReportesMantenimientoTitanium;